import axios from '@/libs/axios'

const endpoint = 'settings/catalog/kits'

const getKits = ({ commit }) => new Promise((resolve, reject) => {
  axios.get(endpoint)
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.error(error)
      reject(error)
    })
})

const updateKits = (ctx, data) => axios.put(`${endpoint}?kits_rules=${data.kits_rules}`)

export default {
  getKits,
  updateKits,
}
