<template>
  <div>
    <b-row class="checkboxGroup" align-v="center" align-h="start">
      <b-col sm="0">
        <b-skeleton
            width="20px"
            height="20px"
            style="border-radius: 5px; margin-right: 10px;"
        />
      </b-col>
      <b-col md="5">
        <span
            style="vertical-align: center; margin-bottom: 14px;"
        >{{ kitsFields['kits_rules'].label }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BCol, BRow, BSkeleton } from 'bootstrap-vue'
import config from '../kitConfig'

export default {
  name: 'Skeleton',
  components: { BSkeleton, BCol, BRow },
  setup() {
    const { kitsFields } = config()
    return {
      kitsFields,
    }
  },
}

</script>
