import {
  CHECKBOX_LEFT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const kitsFields = {
    kits_rules: {
      type: CHECKBOX_LEFT,
      label: 'Replace with alternatives automatically when a product isn’t available. ',
    },
  }
  return {
    kitsFields,
  }
}
