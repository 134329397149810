export default {
  GET(state, data) {
    state.kitRules = { ...data }
  },
  GET_CLONE(state, data) {
    state.kitRulesClone = { ...data }
  },
  SET_KIT_FORM(state, data) {
    state.kitRules = { ...data }
  },
  SET_KIT_FORM_CLONE(state, data) {
    state.kitRulesClone = { ...data }
  },
}
