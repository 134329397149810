<template>
  <div>
    <error-alert
      :fields="kitsFields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="kitFormVal"
      >
        <component
          :is="field.type"
          v-for="(field, key) in kitsFields"
          :key="key"
          :ref="`${key}_ref`"
          v-model="kitRules[key]"
          :is-editable="loading || isEdit"
          v-bind="getProps(key)"
        />
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-weight-bolder"
                variant="outline-primary"
                @click="this.loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="clear"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>

import {
  BButton, BCol, BForm, BRow,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { required } from '@/libs/validations/validations'
import LCheckboxLeft from '@/views/components/DynamicForm/components/LCheckboxLeft.vue'
import { ValidationObserver } from 'vee-validate'
import config from '../kitConfig'

export default {
  name: 'KitForm',
  components: {
    BForm,
    BButton,
    LCheckboxLeft,
    ErrorAlert,
    ValidationObserver,
    BCol,
    BRow,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: {},
      required,
      loading: false,
    }
  },
  computed: {
    kitRules() {
      return this.$store.state[this.MODULE_NAME].kitRules
    },
    kitRulesClone() {
      return this.$store.state[this.MODULE_NAME].kitRulesClone
    },
    isFormChanged() {
      return JSON.stringify(this.kitRules) !== JSON.stringify(this.kitRulesClone)
    },

  },
  methods: {
    getProps(fieldName) {
      return {
        field: this.kitsFields[fieldName],
        name: fieldName,
      }
    },
    submit() {
      this.$store.dispatch(`${this.MODULE_NAME}/updateKits`, this.kitRules)
        .then(res => {
          const { data } = res.data
          if (res.status === 200) {
            this.$toast.success(this.$t('Successfully saved'))
            this.$store.commit(`${this.MODULE_NAME}/GET`, data)
            this.$store.commit(`${this.MODULE_NAME}/GET_CLONE`, data)
          }
          // this.errorNotification(this, res)
        })
        .catch(err => {
          this.error = true
          this.errorNotification(this, err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    clear() {
      this.$refs.kitFormVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/GET`, {})
      this.$router.push({ name: 'settings-catalog-defaults' })
    },
    loader() {
      this.$refs.kitFormVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/GET`, this.kitRulesClone)
    },

  },
  setup() {
    const MODULE_NAME = 'kits'
    const { kitsFields } = config()
    return {
      MODULE_NAME,
      kitsFields,
    }
  },
}

</script>
