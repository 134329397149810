<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="mr-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <label class="font-weight-bolder font-medium-4">
      {{ $t('Kits Rules') }}
    </label>
    <skeleton v-if="loading" />
    <kit-form
      v-else
      ref="kit-form"
      :is-edit="isEdit"
      @refetch="refetch"
    />
  </div>
</template>

<script>

import store from '@/store'
import catalogModule from '@/store/settings/catalog/kits'
import { onUnmounted } from '@vue/composition-api'
import { VBTooltip } from 'bootstrap-vue'
import KitForm from './components/KitForm.vue'
import Skeleton from './components/Skeleton.vue'
import config from './kitConfig'

export default {
  name: 'Kits',
  components: {
    Skeleton,
    KitForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
  },
  created() {
    this.$emit('updateMenu', 'settings-catalog-kits')
    this.refetch()
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getKits`)
        .then(res => {
          const { data } = res.data
          this.$store.commit(`${this.MODULE_NAME}/GET_CLONE`, data)

          // const mappedObj = {}
          // mappedObj.kits_rules = JSON.parse(data.kits_rules)
        })
        .catch(err => {
          this.error = true
          this.errorNotification(this, err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    isEditAble() {
      this.isEdit = !this.isEdit
    },
  },
  setup() {
    const MODULE_NAME = 'kits'
    const { kitFields } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, catalogModule)

    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })

    return {
      MODULE_NAME,
      kitFields,
    }
  },
}
</script>

<style scoped>

</style>
